import { render, staticRenderFns } from "./CampoClient.vue?vue&type=template&id=079d5d1b&scoped=true"
import script from "./CampoClient.vue?vue&type=script&lang=js"
export * from "./CampoClient.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "079d5d1b",
  null
  
)

export default component.exports