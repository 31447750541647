<template>
  <div>
    <v-card-text>

      <v-row>
        <v-col
            cols="12"
            sm="4"
        >
          <v-text-field
              ref="FabricanteSoftware"
              v-model=fabricante
              label="Fabricante Software"
	      counter
              maxlength="80"	
              prepend-inner-icon="mdi-button-pointer"
              required
          />
        </v-col>

        <v-col
            cols="12"
            sm="8"
        >

          <v-text-field
              ref="RazonSocial"
              v-model=razon_social
              label="Razón Social"
              counter
              maxlength="80"  		
              prepend-inner-icon="mdi-warehouse"

              required
          />
        </v-col>

      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="4"
        >
          <v-text-field
              ref="Nit"
              v-model=nit
              label="Nit"
 	      counter
              maxlength="15" 
              prepend-inner-icon="mdi-card-account-details"

              required
          />
        </v-col>
        <v-col
            cols="12"
            sm="8"
        >
          <v-text-field
              ref="Distribuidor"
              v-model=distribuidor
              label="Distribuidor"
              counter
              maxlength="80" 	
              prepend-inner-icon="mdi-badge-account"

              required
          />
        </v-col>


      </v-row>

      <v-card-actions class="justify-end">

        <v-btn
            text
            style="background-color:rgb(204 33 40);color:white !important"
            @click="updateDataFabricante()"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
      <v-alert
          v-show="successAlert"
          type="success"
          text
      >
        {{ msgSuccessAlert }}
      </v-alert>
    </v-card-text>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'campoClient',
  props: ['token_enterprise', 'token_password', 'fabricante', 'nit', 'razon_social' ,'distribuidor'],

  data () {
    return {
      dataUpdateFabricante: [],
      msgSuccessAlert: 'Datos actualizados exitosamente',
      successAlert: false,
    }
  },
  computed: {
    ...mapState('clients', [
      'updateFabricanteResponse'
    ]),

    updateFabricanteResponse () {
      return this.$store.state.clients.updateFabricanteResponse
    },
  },
  watch:
      {
        updateFabricanteResponse  () {
          if (this.updateFabricanteResponse === true) {
            this.generateAlertSuccess()
            this.$store.dispatch('clients/setUpdateResponseFabricante', false)
          }
        },
      },
  methods: {
    generateAlertSuccess () {
      this.successAlert = true
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    async updateDataFabricante () {
      this.dataUpdateFabricante.fabricante = this.fabricante
      this.dataUpdateFabricante.nit = this.nit
      this.dataUpdateFabricante.razon_social = this.razon_social
      this.dataUpdateFabricante.distribuidor = this.distribuidor
      this.dataUpdateFabricante.token_password = this.token_password
      this.dataUpdateFabricante.token_enterprise = this.token_enterprise
      this.dataUpdateFabricante.user = JSON.parse(atob(localStorage.getItem('uid'))).user
      await this.$store.dispatch('clients/updateFabricante', this.dataUpdateFabricante)
    },
  },
}
</script>

<style scoped>

</style>
